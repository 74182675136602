import React from "react"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import SingleProduct from "../components/single-product-page/singleProduct"

import lipInfusionsSoothingProduct from "../images/product-and-package/lip-infusions-soothing-bleed.svg"
import lipInfusionsSoothingImage from "../images/products/lip-infusions-soothing-large.png"

// related product images
import lipInfusionsHydrationProduct from "../images/product-and-package/lip-infusions-hydration.jpg"
import lipInfusionsNourishProduct from "../images/product-and-package/lip-infusions-nourish.jpg"
//import bootsLogo from "../images/stockists/boots.png"
import ocadoLogo from "../images/stockists/ocado.png"
import sainsburysLogo from "../images/stockists/sainsburys.png"
import superdrugLogo from "../images/stockists/superdrug.png"
import amazonLogo from "../images/stockists/amazon.png"

import everydayIcon from "../images/icons/product-icon/everyday.svg"
import nightIcon from "../images/icons/product-icon/overnight.svg"

const product = {
  image: lipInfusionsSoothingProduct,
  productImage: lipInfusionsSoothingImage,
  productImageStyle: "bleed",
  title: (
    <h1 className="product-overview__heading">Lip Infusions - Soothing.</h1>
  ),
  alt: "Lip Infusions - Soothing.",
  subTitle: (
    <h2 className="product-overview__sub-heading">
      <br />
      Infused with Cucumber Seed Oil and Calendula
      <br />
    </h2>
  ),
  disclaimer: "",
  icons: [
    { title: "everyday", icon: everydayIcon },
    { title: "at night", icon: nightIcon },
  ],
  overviewCopy: (
    <div>
      <br />
      <p>
        Blistex Lip Infusions Soothing has an advanced lip care formula that is
        gentle on your lips but tough on dryness, to soothe irritated lips and
        keep them soft, smooth and conditioned with maximum comfort.
      </p>
      <ul>
        <li>
          Infused with Cucumber Seed Oil and Calendula, known for their soothing
          properties
        </li>
        <li>
          With Natural Beeswax and Coconut Oil to moisturise and protect lips
        </li>
        <li>Dermatologist approved</li>
      </ul>
    </div>
  ),
  listID: "",
  stockists: [
    {
      stockistName: "Sainsbury's",
      stockistImage: sainsburysLogo,
      stockistLink:
        "https://www.sainsburys.co.uk/gol-ui/product/blistex-lip-infusions-soothing-37g",
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Ocado",
      stockistImage: ocadoLogo,
      stockistLink:
        "https://www.ocado.com/products/blistex-lip-infusions-soothing-552889011",
      classModifier: "",
      showLink: "true",
    },
    {
      stockistName: "Amazon",
      stockistLink: "https://www.amazon.co.uk/dp/B09HSBHM2W",
      stockistImage: amazonLogo,
      classModifier: "",
      showLink: "true",
    },
  ],
  whenToUseCopy: (
    <div>
      <p>
        Simply twist the stick and apply evenly to your lips. Re-apply as often
        as you like, especially if you're out and about in dry, cold or sunny
        weather.
      </p>
    </div>
  ),
  ingredients: (
    <p>
      hydrogenated coconut oil, octyldodecanol, euphorbia cerifera (candelilla)
      wax, aroma, cera alba, phenyl trimethicone, caprylic/capric triglyceride,
      bis-diglyceryl polyacyladipate-2, cucumis sativus (cucumber) seed oil,
      dimethicone, myristyl myristate, C10-30 cholestrerol/lanosterol esters,
      jojoba esters, tocopheryl acetate, alumina, arachidyl alcohol, arachidyl
      glucoside, behenyl alcohol, calendula officinalis extract, copernicia
      cerifera (carnauba) wax, phenoxyethanol, polyhydroxystearic acid,
      saccharin, stearic acid, CI 77891
    </p>
  ),
  faqs: [
    {
      question: "Does Lip Infusions Soothing contain a sun protection factor?",
      answer: "No.",
    },
    {
      question:
        "Does this product contain any ingredients derived from animals?",
      answer: "Yes, beeswax.",
    },
    {
      question: "Do you sell this product online?",
      answer:
        "Lip Infusions Soothing is available to buy online through key retailers. Please click on the where to buy link on each product page.",
    },
  ],
  relatedProducts: [
    {
      productName: "Lip Infusions - Hydration.",
      productLink: "/lip-infusions-hydration",
      productImage: lipInfusionsHydrationProduct,
    },
    {
      productName: "Lip Infusions - Nourish.",
      productLink: "/lip-infusions-nourish",
      productImage: lipInfusionsNourishProduct,
    },
  ],
}

const lipInfusionsSoothing = () => (
  <Layout noHeaderInLayout={false} makeFooterSticky={false}>
    <SEO title="Lip Infusions - Soothing." />
    <SingleProduct data={product} inStock={true} outOfStockMsg={null} />
  </Layout>
)

export default lipInfusionsSoothing
